import React, { Component } from 'react';

class NotFound extends Component {
  render() {
    return (
      <div className="container" style={{paddingTop: '200px'}}>
        <div className="row justify-content-center">
          <div className="col-12 col-md-5 col-xl-4 my-5">
            <div className="text-center">
              <h6 className="text-uppercase text-muted mb-4">
                404 error
              </h6>
              <h1 className="display-4 mb-3">
                There’s no page here <span role="img" aria-label="sweat emoji">😅</span>
              </h1>
              <p className="text-muted mb-4">
                Check with the person who sent you the link to see if this is a mistake
              </p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default NotFound
