import React, { Component } from 'react';
import {
  Router,
  Route,
  Switch
} from 'react-router-dom'
import { ToastContainer } from 'react-toastify';
import { createBrowserHistory } from 'history'

// Components
import ScrollToTop from './components/ScrollToTop'

// Routes
import { NotFound } from './routes/NotFound'
import OutboundPage from './routes/OutboundPage'
import InboundPage from './routes/InboundPage';

// Global Style
import 'react-toastify/dist/ReactToastify.css';

// Import Overrides
import './App.css'

const history = createBrowserHistory()

// Unauthed Routes
const routes = () => {
  return (
    <Switch>
      <Route exact path='/o/:pageId' component={OutboundPage} />
      <Route exact path='/i/:pageId' component={InboundPage} />
      <Route component={NotFound} />
    </Switch>
  )
}

const regularRoutes = () => {
  return (
    <ScrollToTop>
      {routes()}
    </ScrollToTop>
  )
}

class App extends Component {
  render() {
    return (
      <React.Fragment>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          draggable={false}
          hideProgressBar
          newestOnTop
          closeOnClick
          pauseOnFocusLoss={false}
          pauseOnVisibilityChange={false}
          pauseOnHover
        />
        <Router history={history}>
          {regularRoutes()}
        </Router>
      </React.Fragment>
    );
  }
}

export default App;
