import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import dig from 'object-dig';

import { Input } from '../Form'
import Button from '../Button'
import Avatar from '../Avatar'
import Spinner from '../Spinner'

import {
  getInboundPageLeads,
  createInboundPageLead
} from '../../services/page'

class LeadPromptModal extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isSubmitting: false,
      isLoading: false,
      minValueToShowSearch: 5,
      leads: [],
      filteredLeads: [],
      showLeadForm: false,
      leadName: '',
      leadEmail: '',
      leadJobTitle: '',
      leadSearch: '',
    }
  }

  componentDidMount() {
    if (this.props.isOpen && this.props.inboundPageId) {
      this.getLeadOptions()
    }
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.isOpen && this.props.inboundPageId && prevProps.inboundPageId !== this.props.inboundPageId) {
      this.getLeadOptions()
    }
  }

  getLeadOptions = () => {
    this.updateState({isLoading: true})
    getInboundPageLeads(this.props.inboundPageId)
    .then((payload) => {
      const leads = dig(payload, 'data', 'leads')
      const showLeadForm = leads.length === 0 ? true : false

      this.updateState({
        leads,
        showLeadForm
      })
    })
    .catch((payload) => {
      const error = dig(payload, 'response', 'data', 'message') || 'Something went wrong'
      toast.error(error)
    })
    .finally(() => {
      this.updateState({
        isLoading: false
      })
    })
  }

  handleChange = (event) => {
    const { target: { value, name } } = event;
    this.updateState({[name]: value})
  }

  handleSearchChange = (event) => {
    const { target: { value } } = event;
    
    const lowercaseSearch = (value || '').toLowerCase()
    const filteredLeads = this.state.leads.filter((lead) => lead.name.toLowerCase().includes(lowercaseSearch))

    this.updateState({leadSearch: value, filteredLeads: filteredLeads})
  }

  updateState = (updatedState) => {
    const newState = Object.assign({}, this.state, updatedState)
    this.setState(newState);
  }

  onLeadSelected = (leadId) => {
    this.props.onSuccess(leadId)
  }

  onCreateLead = (e) => {
    e.preventDefault()

    if (this.isLeadCreateDisabled()) {
      return
    }

    const requestPayload = {
      name: this.state.leadName,
      email: this.state.leadEmail,
      job_title: this.state.leadJobTitle
    }

    this.updateState({isSubmitting: true})
    createInboundPageLead(this.props.inboundPageId, requestPayload)
    .then((payload) => {
      const leadId = dig(payload, 'data', 'id')
      this.onLeadSelected(leadId)
    })
    .catch((payload) => {
      const error = dig(payload, 'response', 'data', 'message') || 'Something went wrong'
      toast.error(error)
    })
    .finally(() => {
      this.updateState({isSubmitting: false})
    })
  }

  renderLeadSearch = () => {
    return (
      <div className="list-group-item pt-1" key="search">
        <div className="align-items-center row">
          <div className="col">
            <Input
              name="leadSearch"
              className="mb-0"
              placeholder="Search to find yourself"
              value={this.state.leadSearch}
              onChange={this.handleSearchChange}
            />
          </div>
        </div>
      </div>
    )
  }

  renderMoreResults = () => {
    const { leads, filteredLeads, leadSearch, minValueToShowSearch } = this.state
    const leadCount = leadSearch !== '' ? filteredLeads.length : leads.length
    const extraResults = leadCount - minValueToShowSearch

    return (
      <div className="list-group-item" key="more-results">
        <div className="align-items-center row">
          <div className="col">
            <h4 className="mb-0 mt-2 text-center">
              and {extraResults} more results
            </h4>
          </div>
        </div>
      </div>
    )
  }

  renderNoResults = () => {
    return (
      <div className="list-group-item" key="no-results">
        <div className="align-items-center row">
          <div className="col">
            <h4 className="mb-0 mt-2 text-center">
              No results found. Select "New Viewer" to start a demo.
            </h4>
          </div>
        </div>
      </div>
    )
  }

  renderLeadSelection = () => {
    const { leads, filteredLeads, minValueToShowSearch, leadSearch } = this.state
    const numLeads = leads.length
    const showSearch = numLeads > minValueToShowSearch
    const showMoreResults = (leadSearch === '' && numLeads > minValueToShowSearch) || (filteredLeads.length > minValueToShowSearch)
    const showNoResults = leadSearch !== '' && filteredLeads.length === 0

    return (
      <div className="card-body full-height-card">
        <div className="list-group-flush my-n3 list-group">
          {showSearch && this.renderLeadSearch()}
          {this.renderLeads()}
          {showMoreResults && this.renderMoreResults()}
          {showNoResults && this.renderNoResults()}
        </div>
      </div>
    )
  }

  renderLeads = () => {
    const { leads, filteredLeads, leadSearch, minValueToShowSearch } = this.state
    const showLimitedResults = leads.length > minValueToShowSearch

    if (leadSearch) {
      return (filteredLeads || []).slice(0, minValueToShowSearch).map((lead) => {
        return this.renderLead(lead)
      })
    }

    if (showLimitedResults) {
      return leads.slice(0, minValueToShowSearch).map((lead) => {
        return this.renderLead(lead)
      })
    }

    return (leads || []).map((lead) => {
      return this.renderLead(lead)
    })
  }

  renderLead = (lead) => {
    return (
      <div className="list-group-item" key={lead.id}>
        <div className="align-items-center row">
          <div className="col-auto">
            <span className="avatar avatar-sm me-2" style={{cursor: "pointer"}} onClick={() => this.onLeadSelected(lead.id)}>
              <Avatar userName={lead.name} primaryColor={this.props.primaryColor} />
            </span>
          </div>
          <div className="ms-n2 col">
            <h4 className="mb-0" style={{cursor: "pointer"}} onClick={() => this.onLeadSelected(lead.id)}>
              {lead.name}
            </h4>
          </div>
          <div className="col-auto">
            <button type="button" className="btn btn-white btn-sm" onClick={() => this.onLeadSelected(lead.id)}>
              That's Me
            </button>
          </div>
        </div>
      </div>
    )
  }

  isLeadCreateDisabled = () => {
    const { leadName, leadEmail, leadJobTitle } = this.state
    const { requireJobTitle } = this.props

    if (!leadName) {
      return true
    }

    if (!leadEmail) {
      return true
    }

    if (requireJobTitle && !leadJobTitle) {
      return true
    }

    return false
  }

  renderNewLeadForm = () => {
    const { showJobTitle, requireJobTitle } = this.props

    return (
      <form onSubmit={this.onCreateLead}>
        <div className="card-body">
          <Input
            name="leadName"
            placeholder="John Locke"
            value={this.state.leadName}
            labelText="Name"
            onChange={this.handleChange}
            required
          />
          <Input
            name="leadEmail"
            type="email"
            placeholder="jlocke@dharma.com"
            value={this.state.leadEmail}
            labelText="Email"
            onChange={this.handleChange}
            required
          />
          {showJobTitle &&
          <Input
            name="leadJobTitle"
            placeholder="Director of Marketing"
            value={this.state.leadJobTitle}
            labelText="Job Title"
            onChange={this.handleChange}
            required={requireJobTitle}
          />}
        </div>
        <div className="card-footer" style={{display: 'flex', justifyContent: 'flex-end'}}>
          {this.state.leads.length > 0 &&
          <Button buttonType="button" type="white" block={false} onClick={() => this.updateState({showLeadForm: false})}>
            Cancel
          </Button>}
          <Button
            buttonType="submit"
            className="ml-2"
            type="primary"
            primaryColor={this.props.primaryColor}
            block={false}
            disabled={this.isLeadCreateDisabled()}
            loading={this.state.isSubmitting}
          >
            Start Demo
          </Button>
        </div>
      </form>
    )
  }

  renderNewViewerButton = () => {
    if (this.state.showLeadForm) {
      return null
    }

    if (this.state.leads.length === 0) {
      return null
    }

    return (
      <Button
        block={false}
        type="primary"
        primaryColor={this.props.primaryColor}
        size="sm"
        onClick={() => this.updateState({showLeadForm: true})}
      >
        New Viewer
      </Button>
    )
  }

  renderBody = () => {
    if (this.state.isLoading) { 
      return (
        <div className="card-body text-center">
          <Spinner spinnerSize={1.5} />
        </div>
      )
    }

    if (this.state.showLeadForm) {
      return this.renderNewLeadForm()
    }

    return this.renderLeadSelection()
  }

  render() {
    return (
      <Modal show={this.props.isOpen} onHide={this.onCancel} aria-labelledby='ModalHeader'>
        <div className="modal-card card">
          <div className="card-header">
            <h4 className="card-header-title">
              Personalized demo for {this.props.companyName}
            </h4>
            {this.renderNewViewerButton()}
          </div>
          {this.renderBody()}
        </div>
      </Modal>
    )
  }
}

LeadPromptModal.propTypes = {
  companyName: PropTypes.string,
  inboundPageId: PropTypes.string,
  showJobTitle: PropTypes.bool,
  requireJobTitle: PropTypes.bool,
  primaryColor: PropTypes.string,
  onCancel: PropTypes.func,
  onSuccess: PropTypes.func,
  isOpen: PropTypes.bool
}

LeadPromptModal.defaultProps = {
  onCancel: () => {},
  onSuccess: () => {},
  isOpen: false,
  showJobTitle: false,
  requireJobTitle: false
}

export default LeadPromptModal