import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Script from 'react-load-script'
import dig from 'object-dig';
import queryString from 'qs';

import HeaderNav from '../../components/HeaderNav'
import Alert from '../../components/Alert'

import {
  getOutboundPage,
  sendActivity
} from '../../services/page'

class OutboundPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      primaryColor: '#2C7BE5',
      secondaryColor: '#E4E4E4',
      senderName: 'Salesbot',
      senderEmail: null,
      logoUrl: null,
      logoTargetUrl: null,
      senderAvatarUrl: null,
      subnavUrls: [],
      outboundPageId: null,
      leadId: null,
      flowId: null,
      isFlowPublished: true,
      publicApiKey: null,
      isLoading: false
    }
  }

  componentDidMount() {
    this.fetchPage()
  }

  fetchPage = () => {
    const { match: { params: { pageId } } } = this.props;

    this.updateState({isLoading: true})
    getOutboundPage(pageId)
    .then((payload) => {
      const page = dig(payload, 'data')
      const primaryColor = dig(page, 'primary_color')
      const secondaryColor = dig(page, 'secondary_color')
      const logoUrl = dig(page, 'logo_url')
      const logoTargetUrl = dig(page, 'logo_target_url')
      const subnavUrls = dig(page, 'subnav_urls')
      const senderName = dig(page, 'sender_name')
      const senderEmail = dig(page, 'sender_email')
      const senderAvatarUrl = dig(page, 'sender_avatar_url')
      const outboundPageId = dig(page, 'id')
      const leadId = dig(page, 'lead_id')
      const flowId = dig(page, 'flow_id')
      const isFlowPublished = dig(page, 'flow_published')
      const publicApiKey = dig(page, 'public_api_key')

      this.updateState({
        primaryColor,
        secondaryColor,
        logoUrl,
        logoTargetUrl,
        senderName,
        senderEmail,
        senderAvatarUrl,
        subnavUrls,
        outboundPageId,
        leadId,
        flowId,
        isFlowPublished,
        publicApiKey
      })

      // Log that they opened this
      this.onSendActivity('opened')
    })
    .catch((payload) => {
      const statusCode = dig(payload, 'response', 'status')
      const error = dig(payload, 'response', 'data', 'message') || 'Something went wrong'
      console.error(error)

      // Redirect to not found if we can't find the page
      if (statusCode === 404) {
        this.props.history.push('/')
      }
    })
    .finally(() => {
      this.updateState({
        isLoading: false
      })
    })
  }

  updateState = (updatedState) => {
    const newState = Object.assign({}, this.state, updatedState)
    this.setState(newState);
  }

  onScriptLoad = () => {
    console.log('Script loaded')
  }

  onScriptCreate() {
    console.log('Script create')
  }
  
  onScriptError() {
    console.log('Script error')
  }

  renderScript = () => {
    if (this.state.publicApiKey) {
      return (
        <Script
          url={`${process.env.REACT_APP_INSTANT_DEMO_WIDGET_URL}/embed-init.js`}
          onCreate={this.onScriptCreate}
          onError={this.onScriptError}
          onLoad={this.onScriptLoad}
          attributes={{
            'data-id': 'instant-demo-init-script',
            'data-token': this.state.publicApiKey,
            'async': true
          }}
        />
      )
    }
  }

  onSendActivity = (event) => {
    // Don't log activity if its a preview
    if (this.isPreview()) {
      return
    }

    const { match: { params: { pageId } } } = this.props;

    const requestPayload = {
      page_id: pageId,
      event
    }

    sendActivity(requestPayload)
    .catch((payload) => {
      const error = dig(payload, 'response', 'data', 'message') || 'Something went wrong'
      console.error(error)
    })
  }

  isPreview = () => {
    const query = queryString.parse(this.props.location.search, { ignoreQueryPrefix: true })
    const { preview } = query

    return !!preview
  }

  widgetPayload = () => {
    const payload = {
      lead_id: this.state.leadId,
      outbound_page_id: this.state.outboundPageId,
      override_config: {
        bot_name: this.state.senderName,
        bot_avatar_url: this.state.senderAvatarUrl,
        sharing_enabled: false,
        launch_button_color: this.state.primaryColor
      }
    }

    if (this.isPreview()) {
      payload.test_mode = true
    }

    return payload
  }

  renderPage = () => {
    const { flowId, leadId, isFlowPublished } = this.state
    const isPreview = this.isPreview()

    const payload = this.widgetPayload()
    const jsonPayload = JSON.stringify(payload)

    return (
      <div className="container">
        <div className="row justify-content-center mt-4">
          <div className="col-12 pl-0 pr-0">
            {isPreview && !isFlowPublished &&
            <Alert type="warning">
              This flow is playable since it is in preview mode, but it is not published. Make sure to publish it prior to sending or it will not play.
            </Alert>}
            <div id="instant-demo-embedded" data-lead-id={leadId} data-flow-id={flowId} data-payload={jsonPayload} />
          </div>
        </div>
      </div>
    )
  }

  render() {
    return (
      <div>
        <HeaderNav
          logoUrl={this.state.logoUrl}
          logoTargetUrl={this.state.logoTargetUrl}
          subnavUrls={this.state.subnavUrls}
          senderName={this.state.senderName}
          senderAvatarUrl={this.state.senderAvatarUrl}
          senderEmail={this.state.senderEmail}
          primaryColor={this.state.primaryColor}
          secondaryColor={this.state.secondaryColor}
        />
        <div className="main-content">
          {this.renderPage()}
        </div>
        {this.renderScript()}
      </div>
    )
  }
}

OutboundPage.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object
}

export default withRouter(OutboundPage)