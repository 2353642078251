import React, { Component } from 'react';
import PropTypes from 'prop-types'

class Spinner extends Component {
  render() {
    const { spinnerSize, marginLeft } = this.props

    return (
      <div className="spinner-border" style={{ width: `${spinnerSize}rem`, height: `${spinnerSize}rem`, marginLeft: `${marginLeft}px` }} role="status">
        <span className="sr-only">Loading...</span>
      </div>
    )
  }
}

Spinner.propTypes = {
  spinnerSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  marginLeft: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
}

Spinner.defaultProps = {
  spinnerSize: 2,
  marginLeft: 0
}

export default Spinner