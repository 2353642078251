export function getInitials(fullName) {
  if (!fullName) {
    return '%%'
  }

  const nameParts = fullName.split(' ')

  if (nameParts.length === 0) {
    return '%%'
  }

  if (nameParts.length === 1) {
    return nameParts[0].substring(0,1).toUpperCase()
  }

  return `${nameParts[0].substring(0,1)}${nameParts[1].substring(0,1)}`.toUpperCase()
}