import React, { Component } from 'react';
import PropTypes from 'prop-types'

import {
  getInitials
} from '../../utils/initials'

class Avatar extends Component {
  render() {
    const { userName, avatarUrl, fallbackIcon, primaryColor } = this.props

    const customStyles = {}
    if (primaryColor) {
      customStyles.backgroundColor = primaryColor
    }

    if (!userName && !avatarUrl && fallbackIcon) {
      return (
        <span style={customStyles} className="avatar-title rounded-circle"><i className={`fe fe-${fallbackIcon}`} /></span>
      )
    }

    const avatarTitleImg = (
      <span style={customStyles} className="avatar-title rounded-circle">{getInitials(userName)}</span>
    )

    const avatarImg = avatarUrl ? (
      <img className="avatar-img rounded-circle" src={avatarUrl} alt="Avatar" />
    ) : avatarTitleImg

    return avatarImg
  }
}

Avatar.propTypes = {
  userName: PropTypes.string,
  avatarUrl: PropTypes.string,
  fallbackIcon: PropTypes.string,
  primaryColor: PropTypes.string
}

export default Avatar