import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

class Button extends Component {
  render() {
    const {type, block, buttonType, className, disabled, size, loading, primaryColor} = this.props

    const customStyle = {}

    if (primaryColor) {
      customStyle.backgroundColor = primaryColor
      customStyle.borderColor = primaryColor
    }

    if (loading) {
      return (
        <button
          onClick={this.props.onClick}
          type={buttonType}
          style={customStyle}
          className={
            cx(`btn btn-${type}`, block ? 'btn-block' : undefined, size ? `btn-${size}` : undefined, className ? className : undefined)}
          disabled
        >
          <span className="spinner-border spinner-border-sm mr-3" role="status" aria-hidden="true" />
          {this.props.children}
        </button>
      )
    }
  
    return (
      <button
        onClick={this.props.onClick}
        type={buttonType}
        style={customStyle}
        className={
          cx(`btn btn-${type}`, block ? 'btn-block' : undefined, size ? `btn-${size}` : undefined, className ? className : undefined)}
        disabled={disabled}
      >
        {this.props.children}
      </button>
    )
  }
}

Button.propTypes = {
  block: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  type: PropTypes.oneOf([
    'default',
    'primary',
    'danger',
    'outline-danger',
    'success',
    'outline-primary',
    'outline-success',
    'white',
    'link',
    'secondary'
  ]),
  buttonType: PropTypes.string,
  size: PropTypes.oneOf(['lg', 'sm', 'xs']),
  loading: PropTypes.bool,
  primaryColor: PropTypes.string
}

Button.defaultProps = {
  addRightSpacing: false,
  block: true,
  buttonType: 'button',
  disabled: false,
  label: 'Look at me!',
  onClick: () => {},
  type: 'default',
  loading: false
}

export default Button
