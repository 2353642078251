import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import cx from 'classnames';

import './HeaderNav.css'

class HeaderNav extends Component {
  getInitials() {
    const { senderName } = this.props;


    if (senderName) {
      const nameParts = senderName.split(' ')
      if (nameParts.length === 1) {
        const firstName = nameParts[0]

        return `${firstName.substring(0,1)}`.toUpperCase()
      }

      if (nameParts.length >= 2) {
        const firstName = nameParts[0]
        const lastName = nameParts[1]

        return `${firstName.substring(0,1)}${lastName.substring(0,1)}`.toUpperCase()
      }
    }

    return '%%'
  }

  getAvatarImage() {
    const { senderAvatarUrl } = this.props

    if (senderAvatarUrl) {
      return <img src={senderAvatarUrl} alt="Avatar" className="avatar-img rounded-circle"></img>
    }

    return <span className="avatar-title rounded-circle" style={{backgroundColor: this.props.secondaryColor}}>{this.getInitials()}</span>
  }

  renderUserNav = (isForMobile = false) => {
    const { senderEmail } = this.props
    const showDropdown = !!senderEmail

    return (
      <div className={cx("navbar-user", isForMobile ? "d-md-none" : "d-none d-md-flex")}>
        <div className="dropdown">
          <span className="avatar avatar-sm dropdown-toggle" id="dropdownMenuButton" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            {this.getAvatarImage()}
          </span>
          {showDropdown &&
          <div className="dropdown-menu dropdown-menu-right">
            <a className="dropdown-item" href={`mailto:${senderEmail}?subject=Message from Instant Demo`}>Email Me</a>
          </div>}
        </div>
      </div>
    )
  }

  render() {
    const { logoUrl, logoTargetUrl, subnavUrls } = this.props

    return (
      <nav className="navbar navbar-expand-lg navbar-light" id="topnav">
        <div className="container">
          <button className="navbar-toggler mr-auto" type="button" data-toggle="collapse" data-target="#navbar" aria-controls="navbar" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <a className="navbar-brand nav-logo" href={logoTargetUrl} target="_blank" rel="noopener noreferrer">
            <img src={logoUrl} alt="Logo" className="navbar-brand-img" />
          </a>
          {this.renderUserNav(true)}
          <div className="collapse navbar-collapse mr-lg-auto ml-md-4" id="navbar">
            <ul className="navbar-nav mr-lg-auto">
              {subnavUrls.map((url) => {
                return (
                  <li className="nav-item" key={url.value}>
                    <a className="nav-link" href={url.value} target="_blank" rel="noopener noreferrer">
                      {url.label}
                    </a>
                  </li>
                )
              })}
            </ul>
          </div>
          {this.renderUserNav(false)}
        </div>
      </nav>
    )
  }
}

HeaderNav.propTypes = {
  location: PropTypes.object,
  logoUrl: PropTypes.string,
  logoTargetUrl: PropTypes.string,
  senderName: PropTypes.string,
  senderAvatarUrl: PropTypes.string,
  senderEmail: PropTypes.string,
  primaryColor: PropTypes.string,
  secondaryColor: PropTypes.string,
  subnavUrls: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string
    })
  )
}

export default withRouter(HeaderNav)
