import React, { Component } from 'react';
import PropTypes from 'prop-types'
import cx from 'classnames'

class Alert extends Component {
  render() {
    return (
      <div className={cx(`alert alert-${this.props.type} fade show`, this.props.className ? this.props.className : undefined, this.props.dismissable ? "alert-dismissible" : undefined)} role="alert">
        {this.props.children}
        {this.props.dismissable && 
        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>}
      </div>
    )
  }
}

Alert.propTypes = {
  type: PropTypes.oneOf(['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']),
  children: PropTypes.node,
  className: PropTypes.string,
  dismissable: PropTypes.bool
}

Alert.defaultProps = {
  type: 'secondary',
  dismissable: false
}

export default Alert