const axios = require('axios');

export const getOutboundPage = (pageId) => {
  const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }

  return axios.get(`${process.env.REACT_APP_INSTANT_DEMO_WIDGET_API_URL}/outbound_pages/${pageId}`, {
    headers: headers
  })
}

export const sendActivity = (payload = {}) => {
  const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }

  return axios.post(`${process.env.REACT_APP_INSTANT_DEMO_WIDGET_API_URL}/outbound_pages/activity`, payload, {
    headers: headers
  })
}

export const getInboundPage = (pageId) => {
  const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }

  return axios.get(`${process.env.REACT_APP_INSTANT_DEMO_WIDGET_API_URL}/inbound_pages/${pageId}`, {
    headers: headers
  })
}

export const getInboundPageLeads = (pageId) => {
  const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }

  return axios.get(`${process.env.REACT_APP_INSTANT_DEMO_WIDGET_API_URL}/inbound_pages/${pageId}/leads`, {
    headers: headers
  })
}
export const createInboundPageLead = (pageId, payload) => {
  const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }

  return axios.post(`${process.env.REACT_APP_INSTANT_DEMO_WIDGET_API_URL}/inbound_pages/${pageId}/leads`, payload, {
    headers: headers
  })
}