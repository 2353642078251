import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Script from 'react-load-script'
import dig from 'object-dig';
import queryString from 'qs';

import HeaderNav from '../../components/HeaderNav'
import Alert from '../../components/Alert'
import LeadPromptModal from '../../components/LeadPromptModal'

import {
  getInboundPage
} from '../../services/page'

class InboundPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      primaryColor: '#2C7BE5',
      secondaryColor: '#E4E4E4',
      botName: null,
      botAvatarUrl: null,
      replyEmail: null,
      logoUrl: null,
      logoTargetUrl: null,
      subnavUrls: [],
      inboundPageId: null,
      flowId: null,
      leadId: null,
      isFlowPublished: true,
      publicApiKey: null,
      companyName: '',
      isLeadPrompted: false,
      isLeadPromptJobTitleVisible: false,
      isLeadPromptJobTitleRequired: false,
      isLoading: false,
      showLeadPromptModal: false
    }
  }

  componentDidMount() {
    this.fetchPage()
  }

  fetchPage = () => {
    const { match: { params: { pageId } } } = this.props;

    this.updateState({isLoading: true})
    getInboundPage(pageId)
    .then((payload) => {
      const page = dig(payload, 'data')
      const primaryColor = dig(page, 'primary_color')
      const secondaryColor = dig(page, 'secondary_color')
      const logoUrl = dig(page, 'logo_url')
      const logoTargetUrl = dig(page, 'logo_target_url')
      const subnavUrls = dig(page, 'subnav_urls')
      const botName = dig(page, 'bot_name')
      const botAvatarUrl = dig(page, 'bot_avatar_url')
      const replyEmail = dig(page, 'reply_email')
      const inboundPageId = dig(page, 'id')
      const flowId = dig(page, 'flow_id')
      const isFlowPublished = dig(page, 'flow_published')
      const publicApiKey = dig(page, 'public_api_key')
      const companyName = dig(page, 'company_name')
      const isLeadPrompted = dig(page, 'is_lead_prompted')
      const isLeadPromptJobTitleVisible = dig(page, 'is_lead_prompt_job_title_visible')
      const isLeadPromptJobTitleRequired = dig(page, 'is_lead_prompt_job_title_required')

      this.updateState({
        primaryColor,
        secondaryColor,
        logoUrl,
        logoTargetUrl,
        botName,
        botAvatarUrl,
        replyEmail,
        subnavUrls,
        inboundPageId,
        flowId,
        isFlowPublished,
        publicApiKey,
        companyName,
        isLeadPrompted,
        isLeadPromptJobTitleVisible,
        isLeadPromptJobTitleRequired,
        showLeadPromptModal: isLeadPrompted
      })
    })
    .catch((payload) => {
      const statusCode = dig(payload, 'response', 'status')
      const error = dig(payload, 'response', 'data', 'message') || 'Something went wrong'
      console.error(error)

      // Redirect to not found if we can't find the page
      if (statusCode === 404) {
        this.props.history.push('/')
      }
    })
    .finally(() => {
      this.updateState({
        isLoading: false
      })
    })
  }

  updateState = (updatedState) => {
    const newState = Object.assign({}, this.state, updatedState)
    this.setState(newState);
  }

  onLeadPromptSuccess = (leadId) => {
    this.updateState({leadId, showLeadPromptModal: false})
  }

  onScriptLoad = () => {
    console.log('Script loaded')
  }

  onScriptCreate() {
    console.log('Script create')
  }
  
  onScriptError() {
    console.log('Script error')
  }

  renderScript = () => {
    if (this.state.publicApiKey) {
      return (
        <Script
          url={`${process.env.REACT_APP_INSTANT_DEMO_WIDGET_URL}/embed-init.js`}
          onCreate={this.onScriptCreate}
          onError={this.onScriptError}
          onLoad={this.onScriptLoad}
          attributes={{
            'data-id': 'instant-demo-init-script',
            'data-token': this.state.publicApiKey,
            'async': true
          }}
        />
      )
    }
  }

  isPreview = () => {
    const query = queryString.parse(this.props.location.search, { ignoreQueryPrefix: true })
    const { preview } = query

    return !!preview
  }

  getStateQueryParams = () => {
    const query = queryString.parse(this.props.location.search, { ignoreQueryPrefix: true })

    const stateParams = {}
    Object.keys(query).forEach(key => {
      if (key.startsWith('state.')) {
        const stateKey = key.split('state.')[1]
        stateParams[stateKey] = query[key]
      }
    })

    return stateParams
  }

  widgetPayload = () => {
    const payload = {
      inbound_page_id: this.state.inboundPageId,
      override_config: {
        bot_name: this.state.botName,
        bot_avatar_url: this.state.botAvatarUrl,
        sharing_enabled: false,
        launch_button_color: this.state.primaryColor
      }
    }

    if (this.isPreview()) {
      payload.test_mode = true
    }

    if (this.state.leadId) {
      payload.lead_id = this.state.leadId
    }

    // If the page has initial state params, add them
    const initialState = this.getStateQueryParams()
    if (initialState) {
      payload.initial_state = initialState
    }

    return payload
  }

  renderPage = () => {
    const { flowId, isFlowPublished } = this.state
    const isPreview = this.isPreview()

    const payload = this.widgetPayload()
    const jsonPayload = JSON.stringify(payload)

    return (
      <div className="container">
        <div className="row justify-content-center mt-4">
          <div className="col-12 pl-0 pr-0">
            {isPreview && !isFlowPublished &&
            <Alert type="warning">
              This flow is playable since it is in preview mode, but it is not published. Make sure to publish it prior to sending or it will not play.
            </Alert>}
            <div id="instant-demo-embedded" data-flow-id={flowId} data-payload={jsonPayload} />
          </div>
        </div>
      </div>
    )
  }

  renderBody = () => {
    return (
      <>
        <div className="main-content">
          {this.renderPage()}
        </div>
        {this.renderScript()}
      </>
    )
  }

  render() {
    return (
      <div>
        <HeaderNav
          logoUrl={this.state.logoUrl}
          logoTargetUrl={this.state.logoTargetUrl}
          subnavUrls={this.state.subnavUrls}
          senderName={this.state.botName}
          senderAvatarUrl={this.state.botAvatarUrl}
          senderEmail={this.state.replyEmail}
          primaryColor={this.state.primaryColor}
          secondaryColor={this.state.secondaryColor}
        />
        <LeadPromptModal
          companyName={this.state.companyName}
          inboundPageId={this.state.inboundPageId}
          isOpen={this.state.showLeadPromptModal}
          onSuccess={this.onLeadPromptSuccess}
          showJobTitle={this.state.isLeadPromptJobTitleVisible}
          requireJobTitle={this.state.isLeadPromptJobTitleRequired}
          primaryColor={this.state.primaryColor}
        />
        {this.state.showLeadPromptModal ? null : this.renderBody()}
      </div>
    )
  }
}

InboundPage.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object
}

export default withRouter(InboundPage)