import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

class Input extends Component {
  render() {
    const {
      className,
      errorText,
      helperText,
      type,
      name,
      labelText,
      value,
      onChange,
      required,
      readOnly,
      placeholder,
      topRightNode
    } = this.props
    const hasError = !!errorText
    return (
      <div className={cx("form-group", className ? className : undefined)}>
        {labelText && <div className="row">
          <div className="col">
            <label className="form-label">
              {labelText}{required ? ' *' : ''}
            </label>
          </div>
          {topRightNode && 
          <div className="col-auto">
            {topRightNode}
          </div>}
        </div>}
        {helperText && <small className="form-text text-muted">
          {helperText}
        </small>}
        <input
          type={type}
          className={cx("form-control", hasError ? "is-invalid" : undefined)}
          name={name}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          required={required}
          readOnly={readOnly}
          disabled={readOnly}
        />
        {hasError &&
          <small className="invalid-feedback">
            {errorText}
          </small>
        }
      </div>
    )
  }
}

Input.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  value: PropTypes.string.isRequired,
  labelText: PropTypes.string,
  errorText: PropTypes.string,
  helperText: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  topRightNode: PropTypes.node,
  readOnly: PropTypes.bool
}

Input.defaultProps = {
  type: 'text',
  value: '',
  onChange: () => {},
  required: false,
  readOnly: false
}

export default Input
